<template>
    <div id="pageList" class="SaleBillList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">销售单号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入销售单号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所在城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择所在城市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">销售类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('saleType',el)" v-model="pageList.queryParam.saleType" placeholder="请选择销售类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'saleType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">货物类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('goodsTypeId',el)" v-model="pageList.queryParam.goodsTypeId" placeholder="请选择货物类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'goodsTypeId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerId = validForbid(e)" placeholder="请输入客户进行模糊匹配查询..." v-model="pageList.queryParam.customerId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">项目名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入项目名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户报价单：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerQuotationId = validForbid(e)" placeholder="请输入项目客户报价单名称进行模糊匹配查询..." v-model="pageList.queryParam.customerQuotationId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">结算周期：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('jsType',el)" v-model="pageList.queryParam.jsType" placeholder="请选择结算周期" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'jsType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">发票类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('invoiceType',el)" v-model="pageList.queryParam.invoiceType" placeholder="请选择发票类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'invoiceType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">客户经理：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('managerPersonId',el)" v-model="pageList.queryParam.managerPersonId" placeholder="请选择客户经理" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'managerPersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">调度专员：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('schedulePersonId',el)" v-model="pageList.queryParam.schedulePersonId" placeholder="请选择调度专员" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'schedulePersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
<!--                    <el-col :span="3" class="myColTitle">创建时间：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                       <el-date-picker v-model="pageList.queryParam.time" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>-->
<!--                    </el-col>-->
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol>
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="300">
                    <template #default="scope">
                        <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                        </el-button>
                        <el-button @click="chooseDriverQuotation(scope.row)" type="text">
                            <div style="color: #2c3e50">
                                <i class="iconfont icon-gaibian"></i>&nbsp;&nbsp;{{scope.row['F_DRIVER_QUOTATION_ID']?'修改承运商报价单':'选择承运商报价单'}}
                            </div>
                        </el-button>
                        <el-button @click="configProjectCar(scope.row)" type="text">
                            <div style="color:#2980b9">
                                <i class="iconfont icon-cheliang"></i>&nbsp;&nbsp;配置项目车辆
                            </div>
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CODE" label="销售单号"/>
                <el-table-column prop="F_CITY_ID" label="所在城市"/>
                <el-table-column prop="F_SALE_TYPE" label="销售类型"/>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_NAME" label="项目名称"/>
                <el-table-column prop="F_CUSTOMER_QUOTATION_NAME" label="客户报价单"/>
                <el-table-column prop="F_DRIVER_QUOTATION_NAME" label="承运商报价单"/>
                <el-table-column prop="F_JS_TYPE" label="结算周期"/>
                <el-table-column prop="F_INVOICE_TYPE" label="发票类型"/>
                <el-table-column prop="F_POINT" label="票点"/>
                <el-table-column prop="F_MANAGER_PERSON_ID" label="客户经理"/>
                <el-table-column prop="F_SCHEDULE_PERSON_ID" label="调度专员"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    import SaleBillCard from "../../xsgl/saleBill/SaleBillCard";
    import DriverQuotationList from "../driverQuotation/DriverQuotationList";
    import CarList from '../car/CarList';
    export default defineComponent ({
        name: "YlpzList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                goodsData:[],
                managerData:[],
                scheduleData:[],
                pageList: {
                    queryParam: {
                        moduleType:'YlpzList',
                       code:"", 
                       cityId:"", 
                       saleType:"", 
                       customerId:"", 
                       name:"", 
                       goodsTypeId:"", 
                       customerQuotationId:"", 
                       jsType:"", 
                       invoiceType:"", 
                       managerPersonId:"", 
                       schedulePersonId:"", 
                       flag:"", 
                       status:"", 
                       time:"" 
                    },
                    modelComp: SaleBillCard,
                    modelMethod: "/saleBill/pageData"
               }
            })
            onMounted(async ()=>{
                let data=await utils.$$api.getSaleBillPageCompData({});
                dataObj.cityData = utils.$$lghdUtils.dealSelectData(data.cityData);
                dataObj.goodsData = utils.$$lghdUtils.dealSelectData(data.goodsData);
                dataObj.managerData = utils.$$lghdUtils.dealSelectData(data.personData);
                dataObj.scheduleData = utils.$$lghdUtils.dealSelectData(data.personData);
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'saleType'==params.comboId){
                        return [{value:0,label:'项目'},{value:1,label:'临时'}]
                    }
                    if(params && 'goodsTypeId'==params.comboId){
                        return dataObj.goodsData;
                    }
                    if(params && 'jsType'==params.comboId){
                        return [{value:0,label:'日结'},{value:1,label:'月结'}]
                    }
                    if(params && 'invoiceType'==params.comboId){
                        return [{value:0,label:'专票'},{value:1,label:'普票'}]
                    }
                    if(params && 'managerPersonId'==params.comboId){
                        return dataObj.managerData;
                    }
                    if(params && 'schedulePersonId'==params.comboId){
                        return dataObj.scheduleData;
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:3,label:'已签约（待生效）'},{value:4,label:'已签约（已生效）'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })

            //选择承运商报价单
            const chooseDriverQuotation=async (row)=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'ylpz',saleBillId:row['F_ID'],title: "选择承运商报价单",dialogDiv:"DriverQuotationList",
                    modelComp: DriverQuotationList,proxy:proxy});
            }
            //选择客户报价单确认事件
            const sureHandler=async (modelInst)=>{
                let dialogDiv=modelInst.engineParams.dialogDiv;
                let selectRows = modelInst.pageListRef.getTbInst().getSelection();
                if(selectRows.length>0){
                    let saleBillId=modelInst.pageList.queryParam.saleBillId;
                    let params={};
                    if('DriverQuotationList'==dialogDiv){
                        params={operateType: 'addDriverQuotation',saleBillId:saleBillId,driverQuotationId:selectRows[0]['F_ID']}
                    }else if('CarList'==dialogDiv){
                        let ids='';
                        selectRows.forEach(item=>{
                            ids=ids+item['F_ID']+',';
                        })
                        ids=ids.substr(0,ids.length-1);
                        params={operateType: 'configProjectCar',saleBillId:saleBillId,carIds:ids}
                    }
                    let url ="/saleBill/custom";
                    utils.$$tools.configBox({
                        msgContent:'确定执行该操作吗?',
                        fn:async ()=> {
                            let res = await proxy.utils.$$api.postRequest({ url: url, params: params});
                            if(res.result){
                                if('DriverQuotationList'==dialogDiv)dataObj.pageListRef.queryHandler();
                                utils.$$tools.success({message: '操作成功'});
                            }
                        }
                    });
                }
                return true;
            }
            //配置项目车辆
            const configProjectCar=async (row)=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'ylpz',saleBillId:row['F_ID'],title: "配置项目车辆",dialogDiv:"CarList",
                    modelComp: CarList,proxy:proxy});
            }
            return{
                ...toRefs(dataObj),comboSelect,chooseDriverQuotation,sureHandler,configProjectCar
            }
        }
    });
</script>

<style scoped>
    .SaleBillList{
        width: 100%;
    }
</style>